
<div class="u-flex -column u-gap14" :class="sp && 'u-p10'">
  <TitleHeader :sp="sp" id="academic">学歴</TitleHeader>
  <Card>
    <div v-for="(k, i) in academic_backgrounds" :key="i">
      <div class="u-flex -justify-between -items-center u-mt16 u-mb4" v-if="i > 0">
        <div class="p-form__divider" />
        <button class="p-form__close-button" type="button" v-on:click="deleteAcademicBG(i)">
          <img src="../../../../images/icons/ic_close.svg" />
        </button>
      </div>
      <input type="hidden" name="academic_backgrounds[][id]" :value="k.id">
      <div class="p-form">
        <div class="p-form__group">
          <div class="p-form__heading-block">
            <div class="p-form__label">学校名</div>
          </div>
          <div class="p-form__data-block">
            <input class="p-form__input" type="text" name="academic_backgrounds[][school_name]"
              v-model="academic_backgrounds[i].school_name" placeholder="例）早稲田大学">
          </div>
        </div>
        <div class="p-form__group">
          <div class="p-form__heading-block">
            <div class="p-form__label">学部・学科名</div>
          </div>
          <div class="p-form__data-block">
            <input class="p-form__input" type="text" name="academic_backgrounds[][department_name]"
              v-model="academic_backgrounds[i].department_name" placeholder="例）商学部">
          </div>
        </div>
        <div class="p-form__group">
          <div class="p-form__heading-block">
            <div class="p-form__label">在学期間</div>
          </div>
          <div class="p-form__data-block">
            <div class="u-flex -items-center u-gap4 u-w-full">
              <input class="p-form__input -sm" min="1910" max="2022" type="number"
                name="academic_backgrounds[][begin_year]" v-model="academic_backgrounds[i].begin_year">
              <span>年</span>
              <input class="p-form__input -sm" min="1" max="12" type="number" name="academic_backgrounds[][begin_month]"
                v-model="academic_backgrounds[i].begin_month">
              <span>月 から</span>
            </div>
            <div class="u-flex -items-center u-gap4 u-w-full">
              <input class="p-form__input -sm" min="1910" max="2022" type="number"
                name="academic_backgrounds[][end_year]" v-model="academic_backgrounds[i].end_year">
              <span>年</span>
              <input class="p-form__input -sm" min="1" max="12" type="number" name="academic_backgrounds[][end_month]"
                v-model="academic_backgrounds[i].end_month">
              <span>月 まで</span>
            </div>
            <p class="p-form__desc">※ 在学中の場合は、卒業年月は未入力でお願いします。</p>
          </div>
        </div>
      </div>
    </div>
    <div class="u-mt20 u-flex -justify-center">
      <button class="p-form__add-button" type="button" v-on:click="addAcademicBG()">その他の学歴を追加</button>
    </div>
    <div v-if="sp" class="u-mt20">
      <a class="c-button -simple-green -sp-full" v-on:click="openModal">保存して戻る</a>
    </div>
  </Card>
  <NotifyModal @close="closeModal" @submit="(isNotify) => submitForm(isNotify)" :class="{ active: isModalActive }">
  </NotifyModal>
</div>
