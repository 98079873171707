<template>
  <div class="u-flex -column u-gap14" :class="sp && 'u-p10'">
    <TitleHeader :sp="sp" id="past_careers">職務経歴</TitleHeader>
    <Card>
      <div v-for="(k, i) in past_careers" :key="i">
        <div class="u-flex -justify-between -items-center u-mt16 u-mb4" v-if="i > 0">
          <div class="p-form__divider" />
          <button class="p-form__close-button" type="button" v-on:click="deletePastCareer(i)">
            <img src="../../../../images/icons/ic_close.svg" />
          </button>
        </div>
        <input type="hidden" name="past_careers[][id]" :value="k.id">
        <div class="p-form">
          <div class="p-form__group">
            <div class="p-form__heading-block">
              <div class="p-form__label">会社名</div>
            </div>
            <div class="p-form__data-block">
              <input class="p-form__input" type="text" name="past_careers[][company_name]"
                v-model="past_careers[i].company_name" placeholder="例）株式会社アーキベース">
            </div>
          </div>
          <div class="p-form__group">
            <div class="p-form__heading-block">
              <div class="p-form__label">在職期間</div>
            </div>
            <div class="p-form__data-block">
              <div class="u-flex -items-center u-gap4 u-w-full">
                <input class="p-form__input -sm" min="1910" :max="currentYear" type="number"
                  name="past_careers[][begin_year]" v-model="past_careers[i].begin_year">
                <span>年</span>
                <input class="p-form__input -sm" min="1" max="12" type="number" name="past_careers[][begin_month]"
                  v-model="past_careers[i].begin_month">
                <span>月 から</span>
              </div>
              <div class="u-flex -items-center u-gap4 u-w-full">
                <input class="p-form__input -sm" min="1910" type="number" name="past_careers[][end_year]"
                  v-model="past_careers[i].end_year">
                <span>年</span>
                <input class="p-form__input -sm" min="1" max="12" type="number" name="past_careers[][end_month]"
                  v-model="past_careers[i].end_month">
                <span>月 まで</span>
              </div>
              <p class="p-form__desc">※ 在職中の場合は、退職年月は未入力でお願いします。</p>
            </div>
          </div>
          <div class="p-form__group">
            <div class="p-form__heading-block">
              <div class="p-form__label">雇用形態</div>
            </div>
            <div class="p-form__data-block">
              <select class="p-form__select" name="past_careers[][contract]" v-model="past_careers[i].contract"
                :class="past_careers[i].contract === '' && '-not-selected'">
                <option value="">選択してください</option>
                <option v-for="(k) in contracts" :key="`contract-${k.id}`" :value="k.id">{{ k.name }}</option>
              </select>
            </div>
          </div>
          <div class="p-form__group">
            <div class="p-form__heading-block">
              <div class="p-form__label">業務内容</div>
            </div>
            <div class="p-form__data-block">
              <textarea class="p-form__textarea" rows="7" name="past_careers[][description]" v-model="k.description"
                :placeholder="selfPrPlaceholder"></textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="u-mt20 u-flex -justify-center">
        <button class="p-form__add-button" type="button" v-on:click="addPastCareer()">会社を追加</button>
      </div>
      <div v-if="sp" class="u-mt20">
        <a class="c-button -simple-green -sp-full" v-on:click="openModal">保存して戻る</a>
      </div>
    </Card>
    <NotifyModal @close="closeModal" @submit="(isNotify) => submitForm(isNotify)" :class="{ active: isModalActive }">
    </NotifyModal>
  </div>
</template>

<script>
import axios from 'axios';
import TitleHeader from "../title_header.vue";
import Card from "../card.vue";
import NotifyModal from './notify_modal.vue';
import Button from "../button.vue";
import * as toastr from 'toastr';

const csrf_token = document.head.querySelector("meta[name='csrf-token']").content
const empty_past_career = {
  company_name: "",
  begin_year: "",
  begin_month: "",
  end_year: "",
  end_month: "",
  description: "",
  contract: "",
}
export default {
  props: {
    sp: {
      type: Boolean,
      default: true,
    }
  },
  components: { TitleHeader, Card, Button, NotifyModal },
  data() {
    return {
      past_careers: [],
      contracts: [],
      selfPrPlaceholder: `例）【役割】工事作業者(3年)、現場代理人(1年)
・内部配線工事及び配線器具、照明器具取り付け工事
・スマートメーター取り付け工事
・下請け業者の手配、それに伴う関係図面の手配、工程管理、打ち合わせなど
・施工写真、関係書類の編集及び提出
・追加工事などの見積書の作成
・元請け業者及び事業主による検査の立ち会い`,
      isModalActive: false,
    }
  },
  beforeCreate() {
    const vm = this
    axios.get('/api/mypage/past_careers')
      .then(response => {
        // contractのnullを''に変換
        const past_careers = response.data.map(pa => {
          if (pa.contract === null) pa.contract = ''
          return pa
        })
        vm.$set(vm, 'past_careers', past_careers)
      })
      .then(() => {
        if (vm.past_careers.length < 1) {
          vm.past_careers = [{ ...empty_past_career }]
        }
      })
    axios.get('/api/mypage/contracts')
      .then(response => {
        vm.$set(vm, 'contracts', response.data)
      })
  },
  methods: {
    openModal() {
      this.isModalActive = true
    },
    closeModal() {
      this.isModalActive = false
    },
    addPastCareer() {
      this.past_careers.push({ ...empty_past_career })
    },
    deletePastCareer(i) {
      if (confirm('職歴を削除しますが、よろしいですか？')) {
        axios.delete('/api/mypage/past_careers',
          {
            'params': { 'id': this.past_careers[i].id },
            headers: { 'X-CSRF-Token': csrf_token }
          }
        )
        this.past_careers.splice(i, 1)
      }
    },
    submitForm(isNotify) {
      axios.put(
        '/api/mypage/past_careers',
        {
          "past_careers": this.past_careers,
          "is_notify": isNotify,
        },
        {
          "headers": { "X-CSRF-TOKEN": csrf_token }
        }
      )
        .then(() => {
          if (this.sp) {
            window.location.href = "/mypage/resume?successed=true"
          }
        })
        .catch((e) => {
          for (let err of e.response.data) {
            toastr.error(err)
          }
          // CSRFトークン認証エラーが発生した場合トップヘリダイレクト
          if (e.response.status === 401) {
            window.location.href = '/';
          }
        })
        .finally(() => {
          this.closeModal()
        })
    },
  },
  computed: {
    currentYear() {
      return new Date().getFullYear();
    }
  },
}
</script>
