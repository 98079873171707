<template>
  <div v-if="sp" class="c-title-header u-flex -justify-between -items-center">
    <a href="/mypage/resume" class="c-title-header__side">
      <img
        src="../../../images/icons/ic_arrow_left_black.svg"
        alt="アイコン"
      />
    </a>
    <h1 v-if="sp" class="c-title-header__title">
      <slot />
    </h1>
    <div class="c-title-header__side"></div>
  </div>
  <h2 v-else :id="id" class="c-title-header__title">
    <slot />
  </h2>
</template>

<script>
  export default {
    props: {
    sp: {
      type: Boolean,
      default: true,
    },
    id: String
  },
  }
</script>
