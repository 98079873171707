<template>
  <div class="u-flex -column u-gap14" :class="sp && 'u-p10'">
    <TitleHeader :sp="sp" id="self_pr">自己PR・希望条件</TitleHeader>
    <Card>
      <input v-if="sp" type="hidden" name="referrer" value="self_pr">
      <div class="p-form">
        <div class="p-form__group">
          <div class="p-form__heading-block">
            <div class="p-form__label">自己PR</div>
            <p class="p-form__desc u-mt4">（500文字以内）</p>
          </div>
          <div class="p-form__data-block">
            <textarea class="p-form__textarea" rows="6" name="applicant[profile]" v-model="applicant.profile"
              placeholder="例）私は○年間電気工事士として従事してきました。主にビルなどの物件の内線工事を行ってきました。私が行った最も大きな案件は○○ビルの内線新設工事です。内部の構造が複雑で配線するのが大変でしたが、チーム一丸となって無事故で完工しました。前職での経験を活かし、自分が持っている技能を駆使して業務を遂行していきたいと考えております。また、御社の技術を取得し、更なるスキルの向上にも努めていきたいと思います。"></textarea>
          </div>
        </div>
        <div class="p-form__group">
          <div class="p-form__heading-block">
            <div class="p-form__label">転職希望時期</div>
          </div>
          <div class="p-form__data-block">
            <select class="p-form__select" name="applicant[will_jobchange]" v-model="applicant.will_jobchange"
              :class="applicant.will_jobchange === '' && '-not-selected'">
              <option value="">選択してください</option>
              <option v-for="(will_jobchange, index) in will_jobchange_list" :key="`will_jobchange-${index}`"
                :value="will_jobchange">{{ will_jobchange }}</option>
            </select>
          </div>
        </div>
        <div class="p-form__group">
          <div class="p-form__heading-block">
            <div class="p-form__label -required">希望年収</div>
          </div>
          <div class="p-form__data-block">
            <select class="p-form__select" name="applicant[desired_salary]" v-model="applicant.desired_salary"
              :class="applicant.desired_salary === '' && '-not-selected'">
              <option value="">選択してください</option>
              <option v-for="(desired_salary, index) in desired_salary_list" :key="`desired_salary-${index}`"
                :value="desired_salary">{{ desired_salary }}</option>
            </select>
          </div>
        </div>
        <div class="p-form__group" :class="desiredWorkplacesInputOpen && '-expanded'">
          <div class="p-form__heading-block">
            <div class="p-form__label">希望勤務地</div>
          </div>
          <div class="p-form__data-block -flex-start">
            <Multiselect v-model="applicant.desired_workplace_names" :options="prefectures" :multiple="true"
              :hideSelected="true" :showPointer="!sp" placeholder="希望勤務地を選択" select-label="選択"
              @open="desiredWorkplacesInputOpen = true" @close="desiredWorkplacesInputOpen = false"
              openDirection="bottom"></Multiselect>
          </div>
        </div>
      </div>
      <div v-if="sp" class="u-mt20">
        <a class="c-button -simple-green -sp-full" v-on:click="openModal">保存して戻る</a>
      </div>
    </Card>
    <NotifyModal @close="closeModal" @submit="(isNotify) => submitForm(isNotify)" :class="{ active: isModalActive }">
    </NotifyModal>
  </div>
</template>

<script>
import axios from 'axios';
import TitleHeader from "../title_header.vue";
import Card from "../card.vue";
import Button from "../button.vue";
import * as toastr from 'toastr';
import Multiselect from "vue-multiselect";
import NotifyModal from './notify_modal.vue';
const csrf_token = document.head.querySelector("meta[name='csrf-token']").content

export default {
  props: {
    sp: {
      type: Boolean,
      default: true,
    }
  },
  components: { TitleHeader, Card, Button, Multiselect, NotifyModal },
  data() {
    return {
      applicant: {},
      prefectures: [],
      desired_salary_list: ['300万円以上', '400万円以上', '500万円以上', '600万円以上', '700万円以上', '800万円以上'],
      will_jobchange_list: ['3か月以内に転職したい', '6か月以内に転職したい', '12か月以内に転職したい', '転職の予定はない'],
      desiredWorkplacesInputOpen: false,
      isModalActive: false,
    }
  },
  beforeCreate() {
    const vm = this
    axios.get('/api/mypage/resumes')
      .then(response => {
        // nullから''へ変換
        if (response.data.will_jobchange === null) response.data.will_jobchange = ''
        if (response.data.desired_salary === null) response.data.desired_salary = ''
        vm.$set(vm, 'applicant', response.data)
      })
    axios.get('/api/prefectures?option=name_only')
      .then(response => {
        vm.$set(vm, 'prefectures', response.data)
      })
  },
  methods: {
    openModal() {
      this.isModalActive = true
    },
    closeModal() {
      this.isModalActive = false
    },
    submitForm(isNotify) {
      axios.put(
        '/api/mypage/profile',
        {
          "applicant": this.applicant,
          "referrer": "self_pr",
          "is_notify": isNotify,
        },
        {
          "headers": { "X-CSRF-TOKEN": csrf_token }
        }
      )
        .then(() => {
          if (this.sp) {
            window.location.href = "/mypage/resume?successed=true"
          }
        })
        .catch((e) => {
          // CSRFトークン認証エラーが発生した場合トップヘリダイレクト
          if (e.response.status === 401) {
            window.location.href = '/';
          }
          for (let err of e.response.data) {
            toastr.error(err)
          }
        })
        .finally(() => {
          this.closeModal()
        })
    }
  },
}
</script>
