
<div class="c-modal -center">
  <div class="c-modal__background"></div>
  <div class="c-modal -center u-p20 u-m0">
    <div class="c-modal__header__close" @click="toggleModal"></div>
    <div class="c-modal__header -boarded">
      写真削除の確認
    </div>
    <div class="c-modal__body -text-left u-pt15 u-pb15">
      WEB履歴書用の写真を削除します。<br>
      よろしいでしょうか。
    </div>
    <div class="c-modal__footer -content-center">
      <button
        type="button"
        @click="toggleModal"
        class="c-button -fullwhite -border-gray -no-shadow"
      >
        キャンセル
      </button>
      <button
        type="button"
        @click="deleteProfilePhoto"
        :disabled="isDeletingProfilePhoto"
        class="c-button -red -no-shadow"
      >
        削除
      </button>
    </div>
  </div>
</div>
