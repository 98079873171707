<template>
  <button :id="id" class="c-button -simple-green -sp-full" :type="type" :action="action" @click="clickAction">
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      defualt: 'button'
    },
    id: {
      type: String
    },
    action: {
      type: String
    },
    clickAction: {
      type: Function,
      default: () => {}
    }
  }
}
</script>
