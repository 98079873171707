<template>
  <div class="c-progress-circle__single-chart">
    <svg viewBox="0 0 36 36" class="c-progress-circle__circular-chart">
      <defs>
        <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" :stop-color="gradientColors[0]" />
          <stop offset="100%" :stop-color="gradientColors[1]" />
        </linearGradient>
      </defs>
      <path
        class="c-progress-circle__circle-bg"
        d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
      />
      <path
        class="c-progress-circle__circle"
        stroke="url(#gradient)"
        :stroke-dasharray="`${progress}, 100`"
        d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
      />
      <text
        x="18"
        y="22"
        class="c-progress-circle__percentage"
        :class="colorClass"
      >
        {{ progress }}%
      </text>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    progress: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    gradientColors() {
      if (this.progress <= 45) {
        return ['#ff6f69', '#d73333'];
      } else if (this.progress <= 85) {
        return ['#f6c347', '#f97316'];
      } else {
        return ['#84fab0', '#109954'];
      }
    },
    colorClass() {
      if (this.progress <= 45) {
        return 'u-color-red';
      } else if (this.progress <= 85) {
        return 'u-color-orange-500';
      } else {
        return 'u-color-main';
      }
    },
  },
};
</script>
