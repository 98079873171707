<template>
  <div class="u-px10">
    <div class="l-container">
      <div class="u-pt30 u-flex -items-center u-gap8">
        <h1 class="p-mypage__title u-p0">WEB履歴書</h1>
      </div>
      <input type="hidden" name="referrer" value="all" />
      <div class="u-mt16 u-flex -column u-gap20">
        <Card>
          <h2 class="c-title-header__title">WEB履歴書の完成度</h2>
          <div class="u-flex -justify-center u-gap20">
            <div class="u-flex -column u-gap10">
              <ProgressCircle :progress="scores.total || 0" />
              <div class="c-paper -border -text-center u-font-bold">
                WEB履歴書の完成度が100%に近づくと<br />エントリ通過率アップ!
              </div>
            </div>
            <div class="u-flex -column u-gap10">
              <div class="u-flex -items-center -justify-between">
                <h3>WEB履歴書の入力項目</h3>
                <a
                  href="/mypage/resume/preview"
                  target="_blank"
                  class="c-button -blue -no-shadow -w-auto -ic -box-arrow-up-right -slim u-pl8"
                  >プレビュー</a
                >
              </div>
              <Navitation :scores="scores" :sp="false" />
            </div>
          </div>
          <p
            v-if="scores.last_updated_at"
            class="u-mt20 p-mypage__resume__input_field__date"
          >
            WEB履歴書の最終更新日時: {{ scores.last_updated_at }}
          </p>
        </Card>
        <IconAlert v-if="scores.total < 90" type="error" icon>
          <template v-slot:title
            >WEB履歴書の完成度が90%未満の場合<br class="u-dis-sp-only" /><span
              class="u-dis-pc-only"
              >、</span
            >
            書類選考に進めない可能性があります！</template
          >
        </IconAlert>
        <!-- params を取得するため、refを変更してはいけない -->
        <Profile :sp="false" ref="profile" />
        <PastCareer :sp="false" ref="past_career" />
        <Licenses :sp="false" ref="licenses" />
        <Academic :sp="false" ref="academic_background" />
        <SelfPr :sp="false" ref="self_pr" />
        <div class="u-mt20 u-flex -justify-center">
          <a class="c-button -simple-green" v-on:click="openModal">更新</a>
        </div>
        <div class="u-mt20 u-flex -column u-gap14">
          <div class="c-title-header__title">
            履歴書・職務経歴書のアップロード
          </div>
          <div class="p-mypage__resume__section__body">
            <div class="p-mypage__resume__description">
              既に履歴書・職務経歴書をお持ちの場合はアップロードもできます。<br />
              WEB履歴書で入力されている方はアップロード不要です。
            </div>
            <a
              class="u-pl20 u-pr26 u-py8 c-button -simple-green -green -w-auto -ic -chevron-right"
              href="/mypage/resume/upload"
            >
              履歴書・職務経歴書のアップロードはこちら
            </a>
          </div>
        </div>
      </div>
    </div>
    <NotifyModal
      @close="closeModal"
      @submit="(isNotify) => submitForm(isNotify)"
      :class="{ active: isModalActive }"
    >
    </NotifyModal>
  </div>
</template>

<script>
import Profile from './profile.vue';
import Academic from './academic.vue';
import Licenses from './licenses.vue';
import PastCareer from './past_career.vue';
import SelfPr from './self_pr.vue';
import Card from '../card.vue';
import ProgressCircle from '../progress_circle.vue';
import Navitation from './navigation.vue';
import * as toastr from 'toastr';
import axios from 'axios';
import IconAlert from '../icon_alert.vue';
import NotifyModal from './notify_modal.vue';
const csrf_token = document.head.querySelector(
  "meta[name='csrf-token']"
).content;

export default {
  components: {
    Profile,
    Academic,
    Licenses,
    PastCareer,
    SelfPr,
    Card,
    ProgressCircle,
    Navitation,
    IconAlert,
    NotifyModal,
  },
  data() {
    return {
      scores: {},
      isModalActive: false,
    };
  },
  beforeCreate() {
    axios
      .get('/api/mypage/resume_scores')
      .then((response) => this.$set(this, 'scores', response.data));
  },
  mounted() {
    // アンカーリンクの移動をスムーズにする
    document.querySelector('html').style.scrollBehavior = 'smooth';

    // クエリパラメータで「successed=true」があれば「更新されました」を表示
    const params = location.search;
    if (!params) return;
    const paramList = params.slice(1).split('&');
    const successed = paramList.includes('successed=true');
    if (successed) {
      toastr.success('更新されました。');
    }
  },
  methods: {
    openModal() {
      this.isModalActive = true;
    },
    closeModal() {
      this.isModalActive = false;
    },
    submitForm(isNotify) {
      const refs = this.$refs;
      const payload = {
        applicant: {
          name: refs.profile.applicant.name,
          kana: refs.profile.applicant.kana,
          birth_year: refs.profile.processed_birth_year,
          birth_date: refs.profile.applicant.birth_date,
          sex: refs.profile.applicant.sex,
          email: refs.profile.applicant.email,
          tel: refs.profile.applicant.tel,
          postal_code: refs.profile.applicant.postal_code,
          prefecture_id: refs.profile.applicant.prefecture_id,
          city: refs.profile.applicant.city,
          address: refs.profile.applicant.address,
          has_spouse: refs.profile.applicant.has_spouse,
          salary: refs.profile.applicant.salary,
          profile: refs.self_pr.applicant.profile,
          will_jobchange: refs.self_pr.applicant.will_jobchange,
          desired_salary: refs.self_pr.applicant.desired_salary,
          desired_workplace_names:
            refs.self_pr.applicant.desired_workplace_names,
          license: refs.licenses.registeredLicenses,
          experience: refs.licenses.registeredExperiences,
        },
        is_notify: isNotify,
        academic_backgrounds: refs['academic_background'].academic_backgrounds,
        past_careers: refs['past_career'].past_careers,
      };
      axios
        .put('/api/mypage/resumes', payload, {
          headers: { 'X-CSRF-TOKEN': csrf_token },
        })
        .then(() => {
          window.location.href = '/mypage/resume?successed=true';
        })
        .catch((e) => {
          // CSRFトークン認証エラーが発生した場合トップヘリダイレクト
          if (e.response.status === 401) {
            window.location.href = '/';
          }
          for (const err of e.response.data) {
            toastr.error(err);
          }
        })
        .finally(() => {
          this.closeModal();
        });
    },
  },
};
</script>
