
<div class="c-notify-modal">
  <div class="c-modal__background"></div>
  <div class="c-modal -center u-p20 u-m0">
    <div class="c-modal__header__close" @click="$emit('close')"></div>
    <div class="c-modal__header">
      選考中の応募先の企業に<br> WEB履歴書の更新を通知できます
    </div>
    <div class="modal__body">
      <p>
        更新を通知しますか？
      </p>
      <div>
        <input class="c-radiobutton -v2" id="doNotify" type="radio" name="notify" v-model="isNotify" :value="true">
        <label for="doNotify">通知する</label>
        <input class="c-radiobutton -v2" id="dontNotify" type="radio" name="notify" v-model="isNotify" :value="false">
        <label for="dontNotify">通知しない</label>
      </div>
      <div>
        <p class="u-mb12 helper-text">
          ※通知しない場合、必要に応じて企業へメッセージで連絡してください
        </p>
        <a class="c-button -simple-green" @click="$emit('submit', isNotify)">保存して戻る</a>
      </div>
    </div>
  </div>
</div>
