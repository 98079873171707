
<div class="u-flex -column u-gap14" :class="sp && 'u-p10'">
  <TitleHeader :sp="sp" id="profile">プロフィール</TitleHeader>
  <Card>
    <input v-if="sp" type="hidden" name="referrer" value="profile">
    <div class="p-form">
      <div class="p-form__group">
        <div class="p-form__heading-block">
          <div class="p-form__label">履歴書用の写真</div>
        </div>
        <div class="p-form__data-block">
          <div class="p-mypage__resume__profile-photo-desc">
            <div class="p-mypage__resume__uploaded-profile-photo">
              <img
                v-if="applicant.profile_photo?.url"
                :src="applicant.profile_photo.url"
                alt="プロフィール写真"
                class="p-form__profile-photo"
              >
              <img
                v-else
                src="../../../../images/mypage/default_profile.png"
                alt="プロフィール写真"
                class="p-form__profile-photo"
              >
              <div class="u-ml10">
                <div
                  v-if="applicant.profile_photo?.url"
                  class="p-mypage__resume__photo-link-row u-mb10"
                >
                  <a :href="applicant.profile_photo?.url" target="_blank">
                    {{ applicant.profile_photo?.filename }}
                  </a>
                  <img
                    class="p-mypage__resume__remove-photo"
                    src="../../../../images/icons/ic_bin.svg"
                    alt="プロフィール画像削除"
                    @click="toggleProfileDeleteModal"
                  >
                </div>
                <div class="p-upload_file__button -plain">
                  <label for="profile-photo">
                    <span>写真をアップロード</span>
                    <input
                      id="profile-photo"
                      type="file"
                      :accept="acceptedMimeTypes.join(',')"
                      class="p-form__input -hidden"
                      @change="openProfilePhotoModal"
                    >
                  </label>
                </div>
                <p class="u-mt10">
                  10MBまでの画像をアップロードできます。<br>
                  「jpg, jpeg, png」の拡張子の画像のみ対応しています。<br>
                  写真は縦横4:3のサイズにしてください。<br>
                  例（471 × 354, 560 × 420 px, 600 × 450 px)
                </p>
              </div>
            </div>
            <div>
              <p class="u-mb5">写真のイメージ</p>
              <div class="p-mypage__resume__sample-profile-photo">
                <img
                  src="../../../../images/mypage/profile_male_sample.png"
                  alt="男性サンプル"
                  class="p-form__profile-photo"
                >
                <img
                  src="../../../../images/mypage/profile_female_sample.png"
                  alt="女性サンプル"
                  class="p-form__profile-photo u-ml10"
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="p-form__group">
        <div class="p-form__heading-block">
          <div class="p-form__label -required">氏名</div>
          <p class="p-form__desc u-mt4">（全角）</p>
        </div>
        <div class="p-form__data-block">
          <input type="text" name="applicant[name]" class="p-form__input" v-model="applicant.name"
            placeholder="例）建職　太郎">
        </div>
      </div>
      <div class="p-form__group">
        <div class="p-form__heading-block">
          <div class="p-form__label -required">氏名（カナ）</div>
          <p class="p-form__desc u-mt4">（全角カナ）</p>
        </div>
        <div class="p-form__data-block">
          <input type="text" name="applicant[kana]" class="p-form__input" v-model="applicant.kana"
            placeholder="例）ケンショク　タロウ">
        </div>
      </div>
      <div class="p-form__group">
        <div class="p-form__heading-block">
          <div class="p-form__label -required">生年月日</div>
        </div>
        <div class="p-form__data-block">
          <input type="hidden" name="applicant[birth_date]" :value="birth_date">
          <input type="hidden" name="applicant[birth_year]" :value="processed_birth_year">
          <div class="u-flex -items-center u-gap4 u-w-full">
            <input class="p-form__input -sm" type="number" v-model="birth_year" min="1945">
            <span>年</span>
            <input class="p-form__input -sm" type="number" name="applicant[birth_month]" v-model="birth_month" min="1"
              max="12">
            <span>月</span>
            <input class="p-form__input -sm" type="number" name="applicant[birth_day]" v-model="birth_day" min="1"
              max="31">
            <span>日</span>
          </div>
        </div>
      </div>
      <div class="p-form__group">
        <div class="p-form__heading-block">
          <div class="p-form__label">性別</div>
        </div>
        <div class="p-form__data-block">
          <div class="u-flex -items-center u-gap4 u-w-full">
            <label class="p-form__radio-wrapper" for="sex-1" :class="{ '-checked': applicant.sex === 'male' }">
              <input class="p-form__radio" type="radio" name="applicant[sex]" id="sex-1" value="male"
                v-model="applicant.sex">
              <span class="p-form__radio-text">男性</span>
            </label>
            <label class="p-form__radio-wrapper" for="sex-2" :class="{ '-checked': applicant.sex === 'female' }">
              <input class="p-form__radio" type="radio" name="applicant[sex]" id="sex-2" value="female"
                v-model="applicant.sex">
              <span class="p-form__radio-text">女性</span>
            </label>
            <label class="p-form__radio-wrapper" for="sex-3" :class="{ '-checked': applicant.sex === 'other' }">
              <input class="p-form__radio" type="radio" name="applicant[sex]" id="sex-3" value="other"
                v-model="applicant.sex">
              <span class="p-form__radio-text" for="sex-3">その他</span>
            </label>
          </div>
        </div>
      </div>
      <div class="p-form__group">
        <div class="p-form__heading-block">
          <div class="p-form__label">メールアドレス</div>
        </div>
        <div class="p-form__data-block">
          <p class="p-form__text">{{ applicant.email }}</p>
        </div>
      </div>
      <div class="p-form__group">
        <div class="p-form__heading-block">
          <div class="p-form__label -required">電話番号</div>
          <p class="p-form__desc u-mt4">（半角ハイフンなし）</p>
        </div>
        <div class="p-form__data-block">
          <input type="text" name="applicant[tel]" placeholder="例）09012345678" class="p-form__input"
            v-model="applicant.tel">
        </div>
      </div>
      <div class="p-form__group">
        <div class="p-form__heading-block">
          <div class="p-form__label">郵便番号</div>
          <p class="p-form__desc u-mt4">（半角ハイフンなし）</p>
        </div>
        <div class="p-form__data-block">
          <input type="text" name="applicant[postal_code]" placeholder="例）1001000" class="p-form__input"
            v-model="applicant.postal_code">
        </div>
      </div>
      <div class="p-form__group">
        <div class="p-form__heading-block">
          <div class="p-form__label -required">都道府県</div>
        </div>
        <div class="p-form__data-block">
          <select class="p-form__select" name="applicant[prefecture_id]" v-model="applicant.prefecture_id">
            <option v-for="(prefecture) in prefectures" :key="prefecture.id" :value="prefecture.id">{{ prefecture.name
            }}
            </option>
          </select>
        </div>
      </div>
      <div class="p-form__group">
        <div class="p-form__heading-block">
          <div class="p-form__label -required">市区町村</div>
          <p class="p-form__desc u-mt4">（全角）</p>
        </div>
        <div class="p-form__data-block">
          <input type="text" class="p-form__input" name="applicant[city]" v-model="applicant.city" placeholder="例）千代田区">
        </div>
      </div>
      <div class="p-form__group">
        <div class="p-form__heading-block">
          <div class="p-form__label -required">町名番地以下</div>
        </div>
        <div class="p-form__data-block">
          <input
            type="text"
            name="applicant[address]"
            class="p-form__input"
            v-model="applicant.address"
            placeholder="例）神楽坂二丁目14 ノービィビル3階"
          />
        </div>
      </div>
      <div class="p-form__group">
        <div class="p-form__heading-block">
          <div class="p-form__label">配偶者</div>
        </div>
        <div class="p-form__data-block">
          <div class="u-flex -items-center u-gap4 u-w-full">
            <label class="p-form__radio-wrapper" for="hasSpouse-1"
              :class="{ '-checked': String(applicant.has_spouse) === 'true' }">
              <input class="p-form__radio" type="radio" name="applicant[has_spouse]" id="hasSpouse-1" value="true"
                v-model="applicant.has_spouse">
              <span class="p-form__radio-text">あり</span>
            </label>
            <label class="p-form__radio-wrapper" for="hasSpouse-2"
              :class="{ '-checked': String(applicant.has_spouse) === 'false' }">
              <input class="p-form__radio" type="radio" name="applicant[has_spouse]" id="hasSpouse-2" value="false"
                v-model="applicant.has_spouse">
              <span class="p-form__radio-text">なし</span>
            </label>
          </div>
        </div>
      </div>
      <div class="p-form__group">
        <div class="p-form__heading-block">
          <div class="p-form__label">現在の年収</div>
        </div>
        <div class="p-form__data-block">
          <div class="u-flex -items-center u-gap4 u-w-full">
            <input type="number" name="applicant[salary]" class="p-form__input -sm" v-model="applicant.salary">
            万円
          </div>
        </div>
      </div>
    </div>
    <div v-if="sp" class="u-mt20">
      <a class="c-button -simple-green -sp-full" v-on:click="openModal">保存して戻る</a>
    </div>
  </Card>
  <NotifyModal @close="closeModal" @submit="(isNotify) => submitForm(isNotify)" :class="{ active: isModalActive }">
  </NotifyModal>
  <PhotoUploadModal
    v-if="isProfileModalOpened"
    :class="{ active: isProfileModalOpened }"
    :closeModal="closeProfilePhotoModal"
    :upload="uploadProfilePhoto"
    :isUploadingProfilePhoto="isUploadingProfilePhoto"
    :isPhotoValidationSucceeded="isPhotoValidationSucceeded"
    :openModal="openProfilePhotoModal"
    :photo="selectedPhoto"
  ></PhotoUploadModal>
  <PhotoDeleteModal
    v-if="isProfileDeleteModalOpened"
    :class="{ active: isProfileDeleteModalOpened }"
    :toggleModal="toggleProfileDeleteModal"
    :deleteProfilePhoto="removeProfilePhoto"
    :isDeletingProfilePhoto="isDeletingProfilePhoto"
  ></PhotoDeleteModal>
</div>
