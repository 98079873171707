<template>
  <div class="u-flex -column u-gap14" :class="sp && 'u-p10'">
    <TitleHeader :sp="sp" id="academic">学歴</TitleHeader>
    <Card>
      <div v-for="(k, i) in academic_backgrounds" :key="i">
        <div class="u-flex -justify-between -items-center u-mt16 u-mb4" v-if="i > 0">
          <div class="p-form__divider" />
          <button class="p-form__close-button" type="button" v-on:click="deleteAcademicBG(i)">
            <img src="../../../../images/icons/ic_close.svg" />
          </button>
        </div>
        <input type="hidden" name="academic_backgrounds[][id]" :value="k.id">
        <div class="p-form">
          <div class="p-form__group">
            <div class="p-form__heading-block">
              <div class="p-form__label">学校名</div>
            </div>
            <div class="p-form__data-block">
              <input class="p-form__input" type="text" name="academic_backgrounds[][school_name]"
                v-model="academic_backgrounds[i].school_name" placeholder="例）早稲田大学">
            </div>
          </div>
          <div class="p-form__group">
            <div class="p-form__heading-block">
              <div class="p-form__label">学部・学科名</div>
            </div>
            <div class="p-form__data-block">
              <input class="p-form__input" type="text" name="academic_backgrounds[][department_name]"
                v-model="academic_backgrounds[i].department_name" placeholder="例）商学部">
            </div>
          </div>
          <div class="p-form__group">
            <div class="p-form__heading-block">
              <div class="p-form__label">在学期間</div>
            </div>
            <div class="p-form__data-block">
              <div class="u-flex -items-center u-gap4 u-w-full">
                <input class="p-form__input -sm" min="1910" max="2022" type="number"
                  name="academic_backgrounds[][begin_year]" v-model="academic_backgrounds[i].begin_year">
                <span>年</span>
                <input class="p-form__input -sm" min="1" max="12" type="number" name="academic_backgrounds[][begin_month]"
                  v-model="academic_backgrounds[i].begin_month">
                <span>月 から</span>
              </div>
              <div class="u-flex -items-center u-gap4 u-w-full">
                <input class="p-form__input -sm" min="1910" max="2022" type="number"
                  name="academic_backgrounds[][end_year]" v-model="academic_backgrounds[i].end_year">
                <span>年</span>
                <input class="p-form__input -sm" min="1" max="12" type="number" name="academic_backgrounds[][end_month]"
                  v-model="academic_backgrounds[i].end_month">
                <span>月 まで</span>
              </div>
              <p class="p-form__desc">※ 在学中の場合は、卒業年月は未入力でお願いします。</p>
            </div>
          </div>
        </div>
      </div>
      <div class="u-mt20 u-flex -justify-center">
        <button class="p-form__add-button" type="button" v-on:click="addAcademicBG()">その他の学歴を追加</button>
      </div>
      <div v-if="sp" class="u-mt20">
        <a class="c-button -simple-green -sp-full" v-on:click="openModal">保存して戻る</a>
      </div>
    </Card>
    <NotifyModal @close="closeModal" @submit="(isNotify) => submitForm(isNotify)" :class="{ active: isModalActive }">
    </NotifyModal>
  </div>
</template>

<script>
import axios from 'axios';
import TitleHeader from "../title_header.vue";
import Card from "../card.vue";
import Button from "../button.vue";
import * as toastr from 'toastr';
import NotifyModal from './notify_modal.vue';

const csrf_token = document.head.querySelector("meta[name='csrf-token']").content
const empty_academic_bg = {
  school_name: "",
  department_name: "",
  begin_year: "",
  begin_month: "",
  end_year: "",
  end_month: "",
}
export default {
  props: {
    sp: {
      type: Boolean,
      default: true,
    }
  },
  components: { TitleHeader, Card, Button, NotifyModal },
  data() {
    return {
      academic_backgrounds: [],
      isModalActive: false,
    }
  },
  beforeCreate() {
    const vm = this
    axios.get('/api/mypage/academic')
      .then(response => {
        vm.$set(vm, 'academic_backgrounds', response.data)
      })
      .then(() => {
        if (vm.academic_backgrounds.length < 1) {
          vm.academic_backgrounds = [{ ...empty_academic_bg }]
        }
      })
  },
  methods: {
    openModal() {
      this.isModalActive = true
    },
    closeModal() {
      this.isModalActive = false
    },
    addAcademicBG() {
      this.academic_backgrounds.push({ ...empty_academic_bg })
    },
    deleteAcademicBG(i) {
      if (confirm('学歴を削除しますが、よろしいですか？')) {
        axios.delete('/api/mypage/academic',
          {
            'params': { 'id': this.academic_backgrounds[i].id },
            headers: { 'X-CSRF-Token': csrf_token }
          }
        )
        this.academic_backgrounds.splice(i, 1)
      }
    },
    submitForm(isNotify) {
      axios.put(
        '/api/mypage/academic',
        {
          "academic_backgrounds": this.academic_backgrounds,
          "is_notify": isNotify,
        },
        {
          "headers": { "X-CSRF-TOKEN": csrf_token }
        }
      )
        .then(() => {
          if (this.sp) {
            window.location.href = "/mypage/resume?successed=true"
          }
        })
        .catch((e) => {
          // CSRFトークン認証エラーが発生した場合トップヘリダイレクト
          if (e.response.status === 401) {
            window.location.href = '/';
          }
          for (let err of e.response.data) {
            toastr.error(err)
          }
        })
        .finally(() => {
          this.closeModal()
        })
    }
  }
}
</script>
