import Resume from './components/resume/resume.vue';
import Profile from './components/resume/profile.vue';
import Academic from './components/resume/academic.vue';
import Licenses from './components/resume/licenses.vue';
import SelfPr from './components/resume/self_pr.vue';
import IndexPc from './components/resume/index_pc.vue';
import PastCareer from './components/resume/past_career.vue';
import ResumeUpload from './components/resume/resume_upload.vue';
import Preview from './components/resume/preview.vue';
import ClientPreview from './components/resume/client_preview.vue';

export default {
  components: {
    Resume,
    Profile,
    Academic,
    Licenses,
    SelfPr,
    PastCareer,
    IndexPc,
    ResumeUpload,
    Preview,
    ClientPreview,
  },
};
