
<div class="u-flex -column u-gap14" :class="sp && 'u-p10'">
  <TitleHeader :sp="sp" id="licenses">資格・経験</TitleHeader>
  <IconAlert v-if="sp">
    <template v-slot:icon>
      <img src="../../../../images/icons/ic_light_bulb.svg" alt="アイコン" />
    </template>
    <template v-slot:title>お持ちの資格を追加しませんか？</template>
    <template v-slot:content>お持ちの資格を1個以上入力すると、あなたの条件にマッチしたスカウトをより多く受け取ることができます！</template>
  </IconAlert>
  <Card>
    <div class="p-form">
      <div class="p-form__group" :class="licenseInputOpen && '-expanded'">
        <div class="p-form__heading-block">
          <label class="p-form__label">お持ちの資格</label>
        </div>
        <div class="p-form__data-block -flex-start">
          <Multiselect class="p-licenses-multi-select" v-model="registeredLicenses" :options="licenses" :multiple="true"
            :hideSelected="true" :showPointer="!sp" placeholder="資格を登録" select-label="選択"
            @open="licenseInputOpen = true" @close="licenseInputOpen = false" openDirection="bottom"></Multiselect>
        </div>
      </div>
      <div class="p-form__group" :class="experienceInputOpen && '-expanded'">
        <div class="p-form__heading-block">
          <label class="p-form__label">業務経験</label>
        </div>
        <div class="p-form__data-block -flex-start">
          <Multiselect v-model="registeredExperiences" :options="experiences" :multiple="true" :hideSelected="true"
            :showPointer="!sp" placeholder="経験を登録" select-label="選択" @open="experienceInputOpen = true"
            @close="experienceInputOpen = false" openDirection="bottom"></Multiselect>
        </div>
      </div>
    </div>
    <div v-if="sp" class="u-mt20">
      <a class="c-button -simple-green -sp-full" v-on:click="openModal">保存して戻る</a>
    </div>
  </Card>
  <NotifyModal @close="closeModal" @submit="(isNotify) => submitForm(isNotify)" :class="{ active: isModalActive }">
  </NotifyModal>
</div>
