<template>
  <div class="c-paper u-p0" :class="!sp && '-shadow -min-w320'">
    <a :href="sp ? '/mypage/resume/profile' : '#profile'" class="p-mypage__resume__input_field">
      <img class="p-mypage__resume__input_field__icon" src="../../../../images/icons/ic_check.svg" :class="scores.uncompleted_profile === false && '-done'" />
      <div>
        <div class="p-mypage__resume__input_field__title">プロフィール</div>
        <div v-if="scores.remaining_profile > 0" class="p-mypage__resume__input_field__descriptor u-mt2">
          必須項目が<b>残り{{scores.remaining_profile}}項目</b>の入力で完了
        </div>
        <div v-else-if="scores.uncompleted_profile === false" class="p-mypage__resume__input_field__descriptor u-mt2">
          <b>入力完了</b>
        </div>
      </div>
      <div
        v-if="scores.uncompleted_profile"
        class="p-mypage__resume__input_field__input-missing"
      >
        <span>未入力あり</span>
      </div>
    </a>
    <a :href="sp ? '/mypage/resume/past_careers' : '#past_careers'" class="p-mypage__resume__input_field">
      <img class="p-mypage__resume__input_field__icon" src="../../../../images/icons/ic_check.svg" :class="scores.uncompleted_past_careers === false && '-done'" />
      <div>
        <div class="p-mypage__resume__input_field__title">職務経歴</div>
        <div v-if="scores.remaining_past_careers > 0" class="p-mypage__resume__input_field__descriptor u-mt2">
          <b>残り{{scores.remaining_past_careers}}項目</b>の入力で完了
        </div>
        <div v-else-if="scores.uncompleted_past_careers === false" class="p-mypage__resume__input_field__descriptor u-mt2">
          <b>入力完了</b>
        </div>
      </div>
      <div
        v-if="scores.uncompleted_past_careers"
        class="p-mypage__resume__input_field__input-missing"
      >
        <span>未入力あり</span>
      </div>
    </a>
    <a :href="sp ? '/mypage/resume/licenses' : '#licenses'" class="p-mypage__resume__input_field">
      <img class="p-mypage__resume__input_field__icon" src="../../../../images/icons/ic_check.svg" :class="scores.uncompleted_licenses_and_experiences === false && '-done'" />
      <div>
        <div class="p-mypage__resume__input_field__title">資格・経験</div>
        <div v-if="scores.remaining_licenses_and_experiences > 0" class="p-mypage__resume__input_field__descriptor u-mt2">
          <b>残り{{scores.remaining_licenses_and_experiences}}項目</b>の入力で完了
        </div>
        <div v-else-if="scores.uncompleted_licenses_and_experiences === false" class="p-mypage__resume__input_field__descriptor u-mt2">
          <b>入力完了</b>
        </div>
      </div>
      <div
        v-if="scores.uncompleted_licenses_and_experiences"
        class="p-mypage__resume__input_field__input-missing"
      >
        <span>未入力あり</span>
      </div>
    </a>
    <a :href="sp ? '/mypage/resume/academic' : '#academic'" class="p-mypage__resume__input_field">
      <img class="p-mypage__resume__input_field__icon" src="../../../../images/icons/ic_check.svg" :class="scores.uncompleted_academic === false && '-done'" />
      <div>
        <div class="p-mypage__resume__input_field__title">学歴</div>
        <div v-if="scores.remaining_academic > 0" class="p-mypage__resume__input_field__descriptor u-mt2">
          <b>残り{{scores.remaining_academic}}項目</b>の入力で完了
        </div>
        <div v-else-if="scores.uncompleted_academic === false" class="p-mypage__resume__input_field__descriptor u-mt2">
          <b>入力完了</b>
        </div>
      </div>
      <div
        v-if="scores.uncompleted_academic"
        class="p-mypage__resume__input_field__input-missing"
      >
        <span>未入力あり</span>
      </div>
    </a>
    <a :href="sp ? '/mypage/resume/self_pr' : '#self_pr'" class="p-mypage__resume__input_field">
      <img class="p-mypage__resume__input_field__icon" src="../../../../images/icons/ic_check.svg" :class="scores.uncompleted_self_pr === false && '-done'" />
      <div>
        <div class="p-mypage__resume__input_field__title">自己PR・希望条件</div>
        <div v-if="scores.remaining_self_pr > 0" class="p-mypage__resume__input_field__descriptor u-mt2">
          <b>残り{{scores.remaining_self_pr}}項目</b>の入力で完了
        </div>
        <div v-else-if="scores.uncompleted_self_pr === false" class="p-mypage__resume__input_field__descriptor u-mt2">
          <b>入力完了</b>
        </div>
      </div>
      <div
        v-if="scores.uncompleted_self_pr"
        class="p-mypage__resume__input_field__input-missing"
      >
        <span>未入力あり</span>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    scores: Object,
    sp: {
      type: Boolean,
      default: true,
    }
  }
}
</script>
