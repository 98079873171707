<template>
  <div class="c-modal -center">
    <div class="c-modal__background"></div>
    <div class="c-modal -center u-p20 u-m0">
      <div class="c-modal__header__close" @click="closeModal"></div>
      <div class="c-modal__header -boarded">
        写真のアップロード
      </div>
      <div class="c-modal__body -text-left u-pt15 u-pb15">
        <template v-if="isPhotoValidationSucceeded">
          {{ photo.name }}
        </template>
        <template v-else>
          写真のアップロードに失敗しました。<br>
          <span class="c-label -red u-mt10">
            10MBまでの画像をアップロードできます。
          </span>
          <span class="c-label -red u-mt10">
            「jpg, jpeg, png」の拡張子の画像のみ対応しています。
          </span>
          <span class="u-fs12 u-mt10">
            写真は縦横4:3のサイズにしてください。<br>
            例（471 × 354, 560 × 420 px, 600 × 450 px)
          </span>
        </template>
      </div>
      <div class="c-modal__footer">
        <button
          v-if="isPhotoValidationSucceeded"
          type="button"
          @click="upload"
          :disabled="isUploadingProfilePhoto"
          class="c-button -simple-green"
        >
          アップロード
        </button>
        <label v-else class="c-button -fullwhite -border-gray -no-shadow" for="profile-photo">
          <span>写真を選び直す</span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    upload: { type: Function },
    closeModal: { type: Function },
    isUploadingProfilePhoto: { type: Boolean },
    isPhotoValidationSucceeded: { type: Boolean },
    photo: { type: File },
    openModal: { type: Function }
  }
}
</script>
