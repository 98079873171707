
<div class="u-px10">
  <div class="l-container">
    <div class="u-pt30 u-flex -items-center u-gap8">
      <h1 class="p-mypage__title u-p0">WEB履歴書</h1>
    </div>
    <input type="hidden" name="referrer" value="all" />
    <div class="u-mt16 u-flex -column u-gap20">
      <Card>
        <h2 class="c-title-header__title">WEB履歴書の完成度</h2>
        <div class="u-flex -justify-center u-gap20">
          <div class="u-flex -column u-gap10">
            <ProgressCircle :progress="scores.total || 0" />
            <div class="c-paper -border -text-center u-font-bold">
              WEB履歴書の完成度が100%に近づくと<br />エントリ通過率アップ!
            </div>
          </div>
          <div class="u-flex -column u-gap10">
            <div class="u-flex -items-center -justify-between">
              <h3>WEB履歴書の入力項目</h3>
              <a
                href="/mypage/resume/preview"
                target="_blank"
                class="c-button -blue -no-shadow -w-auto -ic -box-arrow-up-right -slim u-pl8"
                >プレビュー</a
              >
            </div>
            <Navitation :scores="scores" :sp="false" />
          </div>
        </div>
        <p
          v-if="scores.last_updated_at"
          class="u-mt20 p-mypage__resume__input_field__date"
        >
          WEB履歴書の最終更新日時: {{ scores.last_updated_at }}
        </p>
      </Card>
      <IconAlert v-if="scores.total < 90" type="error" icon>
        <template v-slot:title
          >WEB履歴書の完成度が90%未満の場合<br class="u-dis-sp-only" /><span
            class="u-dis-pc-only"
            >、</span
          >
          書類選考に進めない可能性があります！</template
        >
      </IconAlert>
      <!-- params を取得するため、refを変更してはいけない -->
      <Profile :sp="false" ref="profile" />
      <PastCareer :sp="false" ref="past_career" />
      <Licenses :sp="false" ref="licenses" />
      <Academic :sp="false" ref="academic_background" />
      <SelfPr :sp="false" ref="self_pr" />
      <div class="u-mt20 u-flex -justify-center">
        <a class="c-button -simple-green" v-on:click="openModal">更新</a>
      </div>
      <div class="u-mt20 u-flex -column u-gap14">
        <div class="c-title-header__title">
          履歴書・職務経歴書のアップロード
        </div>
        <div class="p-mypage__resume__section__body">
          <div class="p-mypage__resume__description">
            既に履歴書・職務経歴書をお持ちの場合はアップロードもできます。<br />
            WEB履歴書で入力されている方はアップロード不要です。
          </div>
          <a
            class="u-pl20 u-pr26 u-py8 c-button -simple-green -green -w-auto -ic -chevron-right"
            href="/mypage/resume/upload"
          >
            履歴書・職務経歴書のアップロードはこちら
          </a>
        </div>
      </div>
    </div>
  </div>
  <NotifyModal
    @close="closeModal"
    @submit="(isNotify) => submitForm(isNotify)"
    :class="{ active: isModalActive }"
  >
  </NotifyModal>
</div>
