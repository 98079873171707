<template>
  <div class="c-icon-alert" :class="`-${type}`">
    <div class="u-flex -items-center u-gap10">
      <slot name="icon" />
      <span v-if="icon" class="c-icon-alert__icon"></span>
      <p class="c-icon-alert__title">
        <slot name="title" />
      </p>
    </div>
    <p v-if="this.$scopedSlots.content" class="u-mt8 c-icon-alert__content">
      <slot name="content" />
    </p>
  </div>
</template>

<script>
export default {
  props: {
    type: String,
    icon: Boolean
  }
}
</script>
