
<Card class="u-mt10">
  <h2 class="p-form__heading u-m0 u-mb8 -h2">履歴書</h2>
  <div class="p-form -preview">
    <div class="p-form__group">
      <div class="p-form__heading-block">
        <div class="p-form__label">履歴書用の写真</div>
      </div>
      <div class="p-form__data-block">
        <img
          v-if="applicant.profile_photo?.url"
          :src="applicant.profile_photo?.url"
          alt="履歴書用の写真"
          class="p-form__profile-photo"
        />
        <p v-else class="p-form__text">未登録</p>
      </div>
    </div>
    <div class="p-form__group">
      <div class="p-form__heading-block">
        <div class="p-form__label">氏名</div>
      </div>
      <div class="p-form__data-block">
        <p v-if="applicant.name" class="p-form__text">
          {{ applicant.name }}
          <span v-if="applicant.age"> ({{ applicant.age }}歳) </span>
        </p>
      </div>
    </div>
    <div class="p-form__group">
      <div class="p-form__heading-block">
        <div class="p-form__label">氏名（カナ）</div>
      </div>
      <div class="p-form__data-block">
        <p v-if="applicant.kana" class="p-form__text">{{ applicant.kana }}</p>
      </div>
    </div>
    <div class="p-form__group">
      <div class="p-form__heading-block">
        <div class="p-form__label">生年月日</div>
      </div>
      <div class="p-form__data-block">
        <p v-if="applicant.birth_date" class="p-form__text">
          {{ applicant.birth_date.replaceAll('-', '/') }}
        </p>
        <p v-else-if="applicant.birth_year" class="p-form__text">
          {{ `${applicant.birth_year}/(未入力)月/(未入力)日` }}
        </p>
      </div>
    </div>
    <div class="p-form__group">
      <div class="p-form__heading-block">
        <div class="p-form__label">性別</div>
      </div>
      <div class="p-form__data-block">
        <p v-if="applicant.sex" class="p-form__text">{{ sexText() }}</p>
      </div>
    </div>
    <div class="p-form__group">
      <div class="p-form__heading-block">
        <div class="p-form__label">メールアドレス</div>
      </div>
      <div class="p-form__data-block">
        <p v-if="applicant.email" class="p-form__text">
          {{ applicant.email }}
        </p>
      </div>
    </div>
    <div class="p-form__group">
      <div class="p-form__heading-block">
        <div class="p-form__label">電話番号</div>
      </div>
      <div class="p-form__data-block">
        <p v-if="applicant.tel" class="p-form__text">{{ applicant.tel }}</p>
      </div>
    </div>
    <div class="p-form__group">
      <div class="p-form__heading-block">
        <div class="p-form__label">現住所</div>
      </div>
      <div class="p-form__data-block">
        <p class="p-form__text">
          <span v-if="postalCodeText()">
            {{ postalCodeText() }}
          </span>
          <br v-if="postalCodeText()" />
          {{ prefectureName }}{{ applicant.city }}
          <br v-if="applicant.address" />
          {{ applicant.address }}
        </p>
      </div>
    </div>
    <div class="p-form__group">
      <div class="p-form__heading-block">
        <div class="p-form__label">配偶者</div>
      </div>
      <div class="p-form__data-block">
        <p v-if="hasSpouseText()" class="p-form__text">
          {{ hasSpouseText() }}
        </p>
      </div>
    </div>
    <div class="p-form__group">
      <div class="p-form__heading-block">
        <div class="p-form__label">現在の年収</div>
      </div>
      <div class="p-form__data-block">
        <p v-if="applicant.salary" class="p-form__text">
          {{ applicant.salary }}万円
        </p>
      </div>
    </div>
    <div class="p-form__group">
      <div class="p-form__heading-block">
        <div class="p-form__label">資格</div>
      </div>
      <div class="p-form__data-block">
        <ul v-if="licenses" class="u-m0">
          <li
            v-for="(licenseName, index) of licenses"
            :key="`license-${index}`"
            class="p-form__text"
          >
            {{ licenseName }}
          </li>
        </ul>
      </div>
    </div>
    <div class="p-form__group">
      <div class="p-form__heading-block">
        <div class="p-form__label">業務経験</div>
      </div>
      <div class="p-form__data-block">
        <ul v-if="experiences" class="u-m0">
          <li
            v-for="(experienceName, index) of experiences"
            :key="`experience-${index}`"
            class="p-form__text"
          >
            {{ experienceName }}
          </li>
        </ul>
      </div>
    </div>
    <div class="p-form__group">
      <div class="p-form__heading-block">
        <div class="p-form__label">学歴</div>
      </div>
      <div class="p-form__data-block">
        <p
          v-for="(ac, index) of academicBackgrounds"
          :key="`ac-${index}`"
          class="p-form__text"
        >
          <span> {{ ac.begin_year }}年{{ ac.begin_month }}月 </span>
          <span> ~ </span>
          <span v-if="ac.end_year">
            {{ ac.end_year }}年{{ ac.end_month }}月
          </span>
          <span v-else> 在学中 </span>
          <br />
          <span> {{ ac.school_name }} {{ ac.department_name }}</span>
        </p>
      </div>
    </div>
    <div class="p-form__group">
      <div class="p-form__heading-block">
        <div class="p-form__label">自己PR</div>
      </div>
      <div class="p-form__data-block">
        <p
          v-if="applicant.profile"
          class="p-form__text"
          v-html="applicant.profile.replaceAll('\n', '<br>')"
        />
      </div>
    </div>
    <div class="p-form__group">
      <div class="p-form__heading-block">
        <div class="p-form__label">転職希望時期</div>
      </div>
      <div class="p-form__data-block">
        <p v-if="applicant.will_jobchange" class="p-form__text">
          {{ applicant.will_jobchange }}
        </p>
      </div>
    </div>
    <div class="p-form__group">
      <div class="p-form__heading-block">
        <div class="p-form__label">希望年収</div>
      </div>
      <div class="p-form__data-block">
        <p v-if="applicant.desired_salary" class="p-form__text">
          {{ applicant.desired_salary }}
        </p>
      </div>
    </div>
    <div class="p-form__group">
      <div class="p-form__heading-block">
        <div class="p-form__label">希望勤務地</div>
      </div>
      <div class="p-form__data-block">
        <p v-if="applicant.desired_workplace_names" class="p-form__text">
          {{ applicant.desired_workplace_names.join(',') }}
        </p>
      </div>
    </div>
    <div class="p-form__group">
      <div class="p-form__heading-block">
        <div class="p-form__label">添付ファイル</div>
      </div>
      <div class="p-form__data-block">
        <div v-if="careerFils.length !== 0" class="u-flex u-gap8">
          <a
            :href="file.url"
            target="_blank"
            class="p-form__file-link"
            v-for="(file, i) of careerFils"
            :key="`file-${i}`"
          >
            {{ file.name }}
          </a>
        </div>
      </div>
    </div>
  </div>
  <h2 class="p-form__heading u-mt16 u-mb8 -h2">職務経歴書</h2>
  <div class="p-form -preview" v-if="pastCareers.length !== 0">
    <div
      class="p-form__group"
      v-for="(career, index) of pastCareers"
      :key="`career-${index}`"
    >
      <div class="p-form__heading-block">
        <div class="p-form__label">
          {{ career.company_name }}
          <span v-if="career.contract">
            ({{ contractName(career.contract) }})
          </span>
        </div>
      </div>
      <div class="p-form__data-block">
        <div class="p-form__text">
          <span> {{ career.begin_year }}年{{ career.begin_month }}月 </span>
          <span> ~ </span>
          <span v-if="career.end_year">
            {{ career.end_year }}年{{ career.end_month }}月
          </span>
          <span v-else> 在職中 </span>
          <br />
          <div v-if="career.description">
            <b>業務内容</b>
            <p
              class="u-mb0"
              v-html="career.description.replaceAll(/(\r\n|\r|\n)/g, '<br>')"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="loading" class="p-form__data-block">
    <div class="u-dis-sp-only p-form__skeleton"></div>
  </div>
  <p v-else class="p-form__text u-mb0">未入力</p>
</Card>
