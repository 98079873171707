<template>
  <div class="l-container">
    <div class="p-mypage__resume__section">
      <div class="p-mypage__resume__h1">WEB履歴書</div>
      <a
        href="/mypage/resume/preview"
        target="_blank"
        class="c-button u-ml-auto -blue -no-shadow -slim u-w100 u-fs11 -ic -box-arrow-up-right u-dis-b"
        >プレビュー
      </a>
      <div class="p-mypage__resume__h2 u-mt12">WEB履歴書の完成度</div>
      <div class="p-mypage__resume__section__body -center">
        <ProgressCircle :progress="scores.total" />
        <div class="c-paper -border -text-center u-font-bold">
          WEB履歴書の完成度が100%に近づくと<br />エントリ通過率アップ!
        </div>
        <div
          v-if="scores.last_updated_at"
          class="u-mt10 p-mypage__resume__input_field__date u-ml-auto"
        >
          最終更新日時: {{ scores.last_updated_at }}
        </div>
      </div>
      <div v-if="scores.total < 90" class="u-mt8">
        <IconAlert type="error" icon>
          <template v-slot:title
            >WEB履歴書の完成度が90%未満の場合<br class="u-dis-sp-only" /><span
              class="u-dis-pc-only"
              >、</span
            >
            書類選考に進めない可能性があります！</template
          >
        </IconAlert>
      </div>
    </div>
    <div class="p-mypage__resume__section">
      <div class="p-mypage__resume__h3">WEB履歴書の入力項目</div>
      <Navitation :scores="scores" />
    </div>
    <div class="p-mypage__resume__section">
      <div class="p-mypage__resume__h3">履歴書・職務経歴書のアップロード</div>
      <div class="p-mypage__resume__section__body u-p10">
        <div class="p-mypage__resume__description">
          既に履歴書・職務経歴書をお持ちの場合はアップロードもできます。<br />
          WEB履歴書で入力されている方はアップロード不要です。
        </div>
        <a
          class="c-button -green -expanded -no-shadow -slim u-fs11 -ic -chevron-right"
          href="/mypage/resume/upload"
        >
          履歴書・職務経歴書のアップロードはこちら
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import ProgressCircle from '../progress_circle.vue';
import Navitation from './navigation.vue';
import * as toastr from 'toastr';
import axios from 'axios';
import IconAlert from '../icon_alert.vue';

export default {
  components: {
    ProgressCircle,
    Navitation,
    IconAlert,
  },
  data() {
    return {
      scores: {},
    };
  },
  beforeCreate() {
    axios
      .get('/api/mypage/resume_scores')
      .then((response) => this.$set(this, 'scores', response.data));
  },
  mounted() {
    // クエリパラメータで「successed=true」があれば「更新されました」を表示
    const params = location.search;
    if (!params) return;
    const paramList = params.slice(1).split('&');
    const successed = paramList.includes('successed=true');
    if (successed) {
      toastr.success('更新されました。');
    }
  },
};
</script>
