
<div class="u-flex -column u-gap14" :class="sp && 'u-p10'">
  <TitleHeader :sp="sp" id="past_careers">職務経歴</TitleHeader>
  <Card>
    <div v-for="(k, i) in past_careers" :key="i">
      <div class="u-flex -justify-between -items-center u-mt16 u-mb4" v-if="i > 0">
        <div class="p-form__divider" />
        <button class="p-form__close-button" type="button" v-on:click="deletePastCareer(i)">
          <img src="../../../../images/icons/ic_close.svg" />
        </button>
      </div>
      <input type="hidden" name="past_careers[][id]" :value="k.id">
      <div class="p-form">
        <div class="p-form__group">
          <div class="p-form__heading-block">
            <div class="p-form__label">会社名</div>
          </div>
          <div class="p-form__data-block">
            <input class="p-form__input" type="text" name="past_careers[][company_name]"
              v-model="past_careers[i].company_name" placeholder="例）株式会社アーキベース">
          </div>
        </div>
        <div class="p-form__group">
          <div class="p-form__heading-block">
            <div class="p-form__label">在職期間</div>
          </div>
          <div class="p-form__data-block">
            <div class="u-flex -items-center u-gap4 u-w-full">
              <input class="p-form__input -sm" min="1910" :max="currentYear" type="number"
                name="past_careers[][begin_year]" v-model="past_careers[i].begin_year">
              <span>年</span>
              <input class="p-form__input -sm" min="1" max="12" type="number" name="past_careers[][begin_month]"
                v-model="past_careers[i].begin_month">
              <span>月 から</span>
            </div>
            <div class="u-flex -items-center u-gap4 u-w-full">
              <input class="p-form__input -sm" min="1910" type="number" name="past_careers[][end_year]"
                v-model="past_careers[i].end_year">
              <span>年</span>
              <input class="p-form__input -sm" min="1" max="12" type="number" name="past_careers[][end_month]"
                v-model="past_careers[i].end_month">
              <span>月 まで</span>
            </div>
            <p class="p-form__desc">※ 在職中の場合は、退職年月は未入力でお願いします。</p>
          </div>
        </div>
        <div class="p-form__group">
          <div class="p-form__heading-block">
            <div class="p-form__label">雇用形態</div>
          </div>
          <div class="p-form__data-block">
            <select class="p-form__select" name="past_careers[][contract]" v-model="past_careers[i].contract"
              :class="past_careers[i].contract === '' && '-not-selected'">
              <option value="">選択してください</option>
              <option v-for="(k) in contracts" :key="`contract-${k.id}`" :value="k.id">{{ k.name }}</option>
            </select>
          </div>
        </div>
        <div class="p-form__group">
          <div class="p-form__heading-block">
            <div class="p-form__label">業務内容</div>
          </div>
          <div class="p-form__data-block">
            <textarea class="p-form__textarea" rows="7" name="past_careers[][description]" v-model="k.description"
              :placeholder="selfPrPlaceholder"></textarea>
          </div>
        </div>
      </div>
    </div>
    <div class="u-mt20 u-flex -justify-center">
      <button class="p-form__add-button" type="button" v-on:click="addPastCareer()">会社を追加</button>
    </div>
    <div v-if="sp" class="u-mt20">
      <a class="c-button -simple-green -sp-full" v-on:click="openModal">保存して戻る</a>
    </div>
  </Card>
  <NotifyModal @close="closeModal" @submit="(isNotify) => submitForm(isNotify)" :class="{ active: isModalActive }">
  </NotifyModal>
</div>
