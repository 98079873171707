<template>
  <div class="l-container">
    <div class="p-mypage__resume__section">
      <div class="p-mypage__resume__h1">履歴書・職務経歴書アップロード</div>
      <div class="p-mypage__resume__description">
        既に履歴書・職務経歴書をお持ちの場合はアップロードもできます。<br />WEB履歴書で入力されている方はアップロード不要です。
      </div>
      <a href="/mypage/resume"
        class="c-button -simple-green -green -ic -chevron-right"
        :class="isSp() ? 'u-mt12 u-m-0-a u-dis-b -slim u-fs11': 'u-mt8 u-pl20 u-pr26 u-py8 -w-auto'"
      >
        WEB履歴書はこちら
      </a>
      <div class="p-mypage__resume__h2 u-mt12 u-p0">
        <div class="p-mypage__resume__section__body" v-if="loaded">
          <div class="p-mypage__resume__description">
            ファイルは合計3つまで保存できます。<br />※
            アップロードできるファイルは *.pdf, *.docx, *.doc, *.xls,<br />
            *.xlsx, *.ppt, *.pptx, 画像ファイルです。
          </div>
          <div :class="{ disabled: career_file_1.toDelete }" class="u-flex -column u-gap4">
            <div class="p-mypage__upload_file">
              <div class="p-upload_file__button">
                <label for="career_file_1">
                  <span>ファイルを選択</span>
                  <input
                    :accept="allowedMime"
                    type="file"
                    id="career_file_1"
                    name="applicant[career_file_1]"
                    v-on:input="setFile"
                  />
                </label>
                <a
                  :href="career_file_1.url"
                  :class="{ '-nofile': !career_file_1.url }"
                  class="p-upload_file__button__descriptor"
                  >{{ career_file_1.text }}</a
                >
              </div>
            </div>
            <div
              class="p-entry-form__input__error"
              v-if="!career_file_1.validExt"
            >
              ※ファイル拡張子が未対応です
            </div>
            <div
              class="p-entry-form__input__error"
              v-if="!career_file_1.validSize"
            >
              ※ファイルサイズが50mbを超えています
            </div>
            <label class="c-checkbox">
              <input
                type="checkbox"
                name="applicant[remove_career_file_1]"
                v-model="career_file_1.toDelete"
              />
              <span class="c-checkbox__text">ファイルの削除</span>
            </label>
          </div>
          <div :class="{ disabled: career_file_2.toDelete }" class="u-flex -column u-gap4">
            <div class="p-mypage__upload_file">
              <div class="p-upload_file__button">
                <label for="career_file_2">
                  <span>ファイルを選択</span>
                  <input
                    :accept="allowedMime"
                    type="file"
                    id="career_file_2"
                    name="applicant[career_file_2]"
                    v-on:input="setFile"
                  />
                </label>
                <a
                  :href="career_file_2.url"
                  :class="{ '-nofile': !career_file_2.url }"
                  class="p-upload_file__button__descriptor"
                  >{{ career_file_2.text }}</a
                >
              </div>
              <div
                class="p-entry-form__input__error"
                v-if="!career_file_2.validExt"
              >
                ※ファイル拡張子が未対応です
              </div>
              <div
                class="p-entry-form__input__error"
                v-if="!career_file_2.validSize"
              >
                ※ファイルサイズが50mbを超えています
              </div>
            </div>
            <label class="c-checkbox">
              <input
                type="checkbox"
                name="applicant[remove_career_file_2]"
                v-model="career_file_2.toDelete"
              />
              <span class="c-checkbox__text">ファイルの削除</span>
            </label>
          </div>
          <div :class="{ disabled: career_file_3.toDelete }" class="u-flex -column u-gap4">
            <div class="p-mypage__upload_file">
              <div class="p-upload_file__button">
                <label for="career_file_3">
                  <span>ファイルを選択</span>
                  <input
                    :accept="allowedMime"
                    type="file"
                    id="career_file_3"
                    name="applicant[career_file_3]"
                    v-on:input="setFile"
                  />
                </label>
                <a
                  :href="career_file_3.url"
                  :class="{ '-nofile': !career_file_3.url }"
                  class="p-upload_file__button__descriptor"
                  >{{ career_file_3.text }}</a
                >
              </div>
            </div>
            <div
              class="p-entry-form__input__error"
              v-if="!career_file_3.validExt"
            >
              ※ファイル拡張子が未対応です
            </div>
            <div
              class="p-entry-form__input__error"
              v-if="!career_file_3.validSize"
            >
              ※ファイルサイズが50mbを超えています
            </div>
            <label class="c-checkbox">
              <input
                type="checkbox"
                name="applicant[remove_career_file_3]"
                v-model="career_file_3.toDelete"
              />
              <span class="c-checkbox__text">ファイルの削除</span>
            </label>
          </div>
          <div class="p-mypage__resume__description">
            ※ ファイルの上限サイズは50MBです。<br />
            ※
            入力いただいた内容、アップロードしたファイルは応募した求人広告の法人が閲覧することができます。
          </div>
          <button
            type="submit"
            :disabled="!isFormValid || !hasFiles"
            action="update"
            class="c-button -simple-green"
            :class="isSp() ? '-expanded': ''"
          >
            変更内容を反映する
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
const allowedMime = [
  "image/jpeg",
  "image/gif",
  "image/png",
  "application/pdf",
  "text/plain",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
].join(",");
const mb = 1048576;
const extWhitelist =
  /(\.txt|\.doc|\.docx|\.xls|\.xlsx|\.ppt|\.pptx|\.pdf|\.jpg|\.jpeg|\.png|\.gif)$/i;

export default {
  data() {
    return {
      career_file_1: {
        text: "選択されていません",
        validSize: true,
        validExt: true,
        cacheFile: null,
        url: null,
        toDelete: false,
      },
      career_file_2: {
        text: "選択されていません",
        validSize: true,
        validExt: true,
        cacheFile: null,
        url: null,
        toDelete: false,
      },
      career_file_3: {
        text: "選択されていません",
        validSize: true,
        validExt: true,
        cacheFile: null,
        url: null,
        toDelete: false,
      },
      allowedMime: allowedMime,
      loaded: false,
    };
  },
  computed: {
    isFormValid() {
      const vm = this;
      for (let i of [1, 2, 3]) {
        let career_file = vm[`career_file_${i}`];
        if (career_file.toDelete) {
          continue;
        }
        if (!career_file.validSize || !career_file.validExt) {
          return false;
        }
      }
      return true;
    },
    hasFiles() {
      return this.career_file_1.text !== '選択されていません' || this.career_file_2.text !== '選択されていません' || this.career_file_1.text !== '選択されていません'
    }
  },
  beforeCreate() {
    const vm = this;
    axios
      .get("/api/mypage/resumes")
      .then((response) => {
        vm.$set(vm.career_file_1, "url", response.data.career_file_1.url);
        vm.$set(vm.career_file_2, "url", response.data.career_file_2.url);
        vm.$set(vm.career_file_3, "url", response.data.career_file_3.url);
      })
      .then(() => {
        for (let i of [1, 2, 3]) {
          let career_file = vm[`career_file_${i}`];
          let url = career_file["url"];
          if (url) {
            let pathlist = url.split("/");
            let filename = decodeURI(pathlist[pathlist.length - 1]);
            vm.$set(career_file, "text", filename);
          }
        }
      })
      .finally(() => {
        vm.loaded = true;
      });
  },
  methods: {
    setFile(e) {
      const vm = this;
      let file = e.target.files[0];
      vm.$set(vm[e.target.id], "cacheFile", file);
      vm.$set(vm[e.target.id], "text", file.name);
      vm.$set(vm[e.target.id], "url", null);
      vm.validateFile(e.target.id);
    },
    validateFile(id) {
      const vm = this;
      let file = vm[id]["cacheFile"];
      if (!extWhitelist.exec(file.name)) {
        this.$set(vm[id], "validExt", false);
      } else {
        this.$set(vm[id], "validExt", true);
      }
      if (file.size > 50 * mb) {
        this.$set(vm[id], "validSize", false);
      } else {
        this.$set(vm[id], "validSize", true);
      }
    },
    isSp() {
      if (window.matchMedia && window.matchMedia('(max-device-width: 640px)').matches) {
        return true;
      } else {
        return false;
      }
    }
  },
};
</script>
